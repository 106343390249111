!(function(o) {
	o.fn.BlockVideoLightBox = function(e) {
		const u = o.extend(
			{
				autoplay: 1,
			},
			e
		);
		o(this).on('click', function(e) {
			const a = o(this).attr('href');
			if (a.match(/(youtube.com)/))
				var p = 'v=',
					t = 1;
			if (a.match(/(youtu.be)/) || a.match(/(vimeo.com\/)+[0-9]/))
				(p = '/'), (t = 3);
			if (a.match(/(vimeo.com\/)+[a-zA-Z]/)) (p = '/'), (t = 5);
			const i = a.split(p)[t].replace(/(&)+(.*)/, '');
			if (a.match(/(youtu.be)/) || a.match(/(youtube.com)/))
				var c =
					'https://www.youtube.com/embed/' +
					i +
					'?autoplay=' +
					u.autoplay;
			if (
				a.match(/(vimeo.com\/)+[0-9]/) ||
				a.match(/(vimeo.com\/)+[a-zA-Z]/)
			)
				c =
					'https://player.vimeo.com/video/' +
					i +
					'?autoplay=' +
					u.autoplay;
			o('body').append(
				'<div class="block-video-lightbox-wrap block-video-lightbox-animation"><div class="block-video-lightbox-content"><span class="block-video-lightbox-close"></span><iframe src="' +
					c +
					'" allowfullscreen></iframe></div></div>'
			),
				o('.block-video-lightbox-wrap').hasClass(
					'block-video-lightbox-animation'
				) &&
					setTimeout(function() {
						o('.block-video-lightbox-wrap').removeClass(
							'block-video-lightbox-animation'
						);
					}, 600),
				o('.block-video-lightbox-wrap, .block-video-lightbox-close').on(
					'click',
					function() {
						o('.block-video-lightbox-wrap')
							.addClass('block-video-lightbox-hide')
							.delay(515)
							.queue(function() {
								o(this).remove();
							});
					}
				);
			e.preventDefault();
		}),
			o(document).on('keyup', function(e) {
				// eslint-disable-next-line no-unused-expressions
				27 === e.keyCode &&
					o(
						'.block-video-lightbox-wrap, .block-video-lightbox-close'
					).trigger('click');
			});
	};
})(jQuery);
